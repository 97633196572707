import api from "./api";
import Axios from "axios";
import queryString from "query-string";
import getResponseErrorMessage from "./getResponseErrorMessage";
import getCookie from "./getCookie";

const cookieDomain =
  process.env.REACT_APP_DEBUG === "false"
    ? ".buttonizer.io"
    : process.env.REACT_APP_COOKIE_DOMAIN;
const loginToken = "__token";

/**
 * Get login token
 */
function getToken() {
  return getCookie(loginToken);
}

/**
 * Remove token
 */
function removeToken() {
  document.cookie = `${loginToken}=;domain=${cookieDomain};path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT`;

  delete Axios.defaults.headers.common["Authorization"];
  console.warn("Your session was expired. Removed token");
}

/**
 * Set token
 * @param {string} token
 */
function setToken(token) {
  const cook = `${loginToken}=${token};${
    process.env.REACT_APP_DEBUG === "false" ? "secure;" : ""
  }domain=${cookieDomain};path=/;`;
  document.cookie = cook;

  Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

// Set default header
if (getToken()) {
  Axios.defaults.headers.common["Authorization"] = "Bearer " + getToken();
}

// Get authentication
function getAuthentication() {
  const token = getToken();

  // When there is no token, return null
  if (!token || token === null) {
    return Promise.resolve(null);
  }

  return api("/auth").catch((error) => {
    if (error.response.status === 401) {
      removeToken();
    }
    return Promise.reject(error);
  });
}

function verifyEmail({ email = "", ...params }) {
  return api("/auth/login/email", { email, ...params }, {}, {}, false)
    .then(() => {
      return Promise.resolve();
    })
    .catch((err) => {
      return Promise.reject(getResponseErrorMessage(err));
    });
}

function login({ email = "", password = "" }) {
  return api("/auth/login", { email, password }, {}, {}, false)
    .then((data) => {
      setToken(data.data.token);
      return Promise.resolve();
    })
    .catch((err) => {
      return Promise.reject(getResponseErrorMessage(err));
    });
}

function authByEmail(params) {
  return api(`/auth/login/magic${params}`, null, {}, {}, false)
    .then((data) => {
      setToken(data.data.token);
      return Promise.resolve();
    })
    .catch((err) => {
      return Promise.reject(getResponseErrorMessage(err));
    });
}

function register({ firstName, lastName, email, password }) {
  return api(
    "/account/create",
    {
      firstName,
      lastName,
      email,
      password,
    },
    {},
    {},
    false
  )
    .then((data) => Promise.resolve(data))
    .catch((err) => Promise.reject(getResponseErrorMessage(err)));
}

// Get user
function logout() {
  return api("/auth/logout", { logout: true })
    .then(() => {
      removeToken();
      Promise.resolve();
    })
    .catch((err) => {
      // Token already expired
      if (err.request.status === 401) {
        removeToken();
      }

      Promise.resolve();
    });
}

/**
 * Create a safe local redirect URL from any path/URL
 *
 * @param {*} originalPath Can be a full URL
 * @param {*} fullUrl Return
 * @returns Safe path to redirect to
 */
function safeRedirectUrl(originalPath, fullUrl = false) {
  // Security -> Make sure the path cannot redirect to other domains other than our own domain
  const url = `${process.env.REACT_APP_URL}/${originalPath.substr(1)}`;

  if (fullUrl) return url;

  const urlObj = new URL(url);

  return `${urlObj.pathname}${urlObj.search}`;
}

/**
 * Redirect user after signing in
 *
 * @param {*} fullUrl
 * @returns
 */
function afterLoginRedirectPath(fullUrl = false) {
  if (window.buttonizerStartLocation.substr(0, 11) !== "/auth/login") {
    return safeRedirectUrl(window.buttonizerStartLocation, fullUrl);
  }

  const routeParams = queryString.parse(window.startSearchQuery);

  // Redirect to the overview
  if (!routeParams.from) return "/overview";

  return safeRedirectUrl(routeParams.from, fullUrl);
}

export {
  safeRedirectUrl,
  verifyEmail,
  getToken,
  setToken,
  getAuthentication,
  afterLoginRedirectPath,
  login,
  authByEmail,
  register,
  logout,
  removeToken,
};
